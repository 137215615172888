<template>
<div id="pageTable">
    <v-container grid-list-xl fluid>
        <v-card>
            <v-layout class="d-flex flex-lg-row flex-md-column flex-sm-column" wrap pa-3>
                <v-flex lg12 md12 sm12 xs12>
                    <v-text-field v-model="staff.Username" ref="username" label="User Name" class="input-group--focused" disabled></v-text-field>
                </v-flex>
                <v-flex lg12 md12 sm12 xs12>
                    <v-text-field v-model="staff.Email" label="Email" class="input-group--focused"></v-text-field>
                </v-flex>
                <v-flex lg12 md12 sm12 xs12>
                    <v-text-field v-model="staff.FullName" label="Họ tên" class="input-group--focused"></v-text-field>
                </v-flex>
                <v-flex lg12 md12 sm12 xs12>
                    <v-select v-model="staff.RoleID" :items="role_list" item-text="name" item-value="value" label="Quyền hạn" disabled class="input-group--focused"></v-select>
                </v-flex>                
                <v-flex lg12 md12 sm12 xs12>
                    <v-checkbox v-model="change_pass" label="Đổi mật khẩu" class="input-group--focused"></v-checkbox>
                </v-flex> 
                <v-flex lg12 md12 sm12 xs12>
                    <v-text-field v-model="password" type="password" label="Mật khẩu mới" :disabled="!change_pass" class="input-group--focused"></v-text-field>
                </v-flex>               
            </v-layout>
        </v-card>
    </v-container>
</div>
</template>

<script>
import { mapState } from "vuex";
import md5 from "md5";
import configs from "@/configs";
export default {
    data() {
        let roles = this.$store.state.roles.all.data.map(obj =>{ 
            var rObj = {};
            rObj['value'] = obj.ID;
            rObj['name'] = obj.RoleName;
            return rObj;
        });
        return {
            role_list: roles,
            password: '',
            change_pass: false
        }
    },
    computed: {
        ...mapState({
            loading: state => state.staff.loading,
            staff: state => state.staff.selected
        }),
        modifyId() {
            return this.$store.state.authentication.user.userid;
        }
    },
    watch: {
        modifyId() {
            this.refreshData();
        }
    },
    methods: {
        refreshData() {
            if (this.modifyId > 0) {
                this.$store.dispatch("staff/getDetail", this.modifyId);
            }
            this.$nextTick(() => this.$refs.username.focus());
        },
        saveData() {
            if(this.change_pass) {
                this.staff.Password = md5(this.password)
            }
            this.$store.dispatch("staff/setDetail", {
                data: this.staff,
                id: this.staff.ID,
                notify: 'Đã cập nhật profile.'
            });
        },
    },
    mounted() {
        this.refreshData();
        this.$root.$emit('REQUEST_ROOT_OPTION', {});
        this.$root.$on("REQUEST_SAVE_DATA", this.saveData);
        if (this.$route.query.uid && this.$route.query.uid !== '') {
            this.staff.UID = Number(this.$route.query.uid);
        }
    },
    beforeDestroy() {
        this.$root.$off("REQUEST_SAVE_DATA", this.saveData);
    },
};
</script>

<style>
</style>
